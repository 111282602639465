<template>
  <div>
    <Card>
      <template v-slot:header>{{ $t('22404a4') }}</template>
      <!-- <DisplayCard :data="data" :desc-meta-data="descMetaData" /> -->
      <Display :data="data" />
    </Card>
    <Case v-if="showCases" class="case" :data="data.cases" />
  </div>
</template>

<script>
import Card from '@/components/Card'
// import Icon from '@/components/content/Icon'
import API from '@/api'

// import DisplayCard from '@/components/content/DisplayCard/index'
import Case from '@/components/Service/ServiceCase'
import Display from '../ServiceItem/detail'
export default {
  name: 'ServiceDetail',
  components: {
    Card,
    // Icon,
    // DisplayCard,
    Display,
    Case,
  },
  data() {
    return {
      data: {},
      descMetaData: [
        { label: '服务标题', key: 'title', span: 24 },
        { label: '服务类型', key: 'serviceType' },
        { label: '业务类型', key: 'businessType' },
        { label: '业务范围', key: 'country' },
        { label: '发布时间', key: 'createTime' },
        { label: '服务编码', key: 'code' },
        { label: '服务量', key: 'num' },
        { label: '所属区域', key: 'province' },
        { label: '联系人', key: 'contactPerson' },
        { label: '联系电话', key: 'contactPhone' },
        { label: '联系邮箱', key: 'email' },
        { label: '服务详情', key: 'content', span: 24 },
      ],
    }
  },
  computed: {
    showCases() {
      const cases = this.data.cases
      return cases ? cases.length : ''
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const id = this.$route.query.id
      if (id) {
        API.service.getLawServiceDetails({ id }).then((res) => {
          this.data = res
        })
      }
    },
    // deleteCard(id) {
    //   API.request.deleteLawService(id).then((res) => {
    //     this.$message({
    //       message: res.message,
    //       type: 'success',
    //     })
    //     this.$router.push('/content/service/manage')
    //   })
    // },
  },
}
</script>

<style scoped lang="less">
.case {
  margin-top: 20px;
}
</style>
